.connectwallteclass {
  color: white;
  display: flex;
  flex-direction: column;
}

.connectWallettextclass {
  font-style: italic;
  font-size: 1.8rem;
  text-align: center;
  margin-top: 2rem;
}

.secretSocietytext {
  position: absolute;
  bottom: 0;
  margin-bottom: 12rem;
  font-style: italic;
  font-size: 1.1rem;
  text-align: center;
}
@media (max-width: 510px) {
  .connectWallettextclass {
    font-style: italic;
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
    margin-top: -8rem;
  }
  .secretSocietytext {
    position: absolute;
    bottom: 0;
    margin-bottom: 14rem;
    font-style: italic;
    font-size: 0.7rem;
    text-align: center;
  }
}
