.mainwelcome22{
    color: antiquewhite;
    display: flex;
    flex-direction: column;
    margin-top: -5rem;
}
.weltext333{
    font-size: 1.5rem;
    line-height: 50px;
    text-align: center;
    
}
.weltext4444{
    font-size: 5rem;
    line-height: 50px;
    text-align: center;
    margin-top: 7rem;
}
.weltext55{
    font-size: 1.5rem;
    line-height: 50px;
    text-align: center;
}
.weltext66{
    font-size: 2rem;
    line-height: 50px;
    text-align: center;
    margin-bottom: 8rem;
}
.weltext5566{
    font-size: 1.5rem;
    text-align: center;
    
}
@media(max-width: 510px){
    .mainwelcome22{
        color: antiquewhite;
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
    }
    .weltext333{
        font-size: 1.2rem;
        line-height: 50px;
        text-align: center;
        
    }
    .weltext4444{
        font-size: 3rem;
        line-height: 50px;
        text-align: center;
        margin-top: 7rem;
    }
    .weltext66{
        font-size: 1.5rem;
        line-height: 50px;
        text-align: center;
        margin-bottom: 8rem;
    }
    .weltext5566{
        font-size: 1.2rem;
        text-align: center;
        
    }
}