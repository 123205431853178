.mainwelcome{
    color: antiquewhite;
    display: flex;
    flex-direction: column;
    margin-top: -10rem;
  
    
}
.welcometext{
    font-style: italic;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: line-through red;
   
     
}
.welcometext44{
    font-style: italic;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 8rem;
     margin-bottom: 4rem;
    
}
.nine{
    color: antiquewhite;
    position: absolute;
    font-size: 2rem;
    left: 0;
    top: 0; 
    margin-left: 7rem;
    margin-top: 2rem;
    
}
 
.line1 {
    width: 110px;
    height: 40px;
    border-bottom: 1px solid rgb(173, 154, 154);
    -webkit-transform:
        translateY(-20px)
        translateX(10px)
        rotate(42deg); 
    position: absolute;
    margin-top: 6rem;
    margin-left: 2rem;
  
}
.line3 {
    width: 110px;
    height: 40px;
    border-bottom: 1px solid rgb(173, 154, 154);
    -webkit-transform:
        translateY(-20px)
        translateX(-5px)
        rotate(-42deg); 
    position: absolute;
    margin-top: 6rem;
    margin-left: 2rem;
   
}
 
 
@media(max-width: 510px){
    .mainwelcome{
        color: antiquewhite;
        display: flex;
        flex-direction: column;
        margin-top: -8rem;
    }
    .welcometext{
        font-style: italic;
        font-size: .8rem;
        text-align: center;
        margin-top: 2rem;
    }
    .nine{
        color: antiquewhite;
        position: absolute;
        font-size: 1.8rem;
        left: 0;
        top: 0; 
        margin-left: 7rem;
        margin-top: 2rem;
    }
     
    .welcometext44{
        font-style: italic;
        font-size: 1rem;
        text-align: center;  
    }
    
    .line1 {
        width: 110px;
        height: 40px;
        border-bottom: 1px solid rgb(173, 154, 154);
        -webkit-transform:
            translateY(-20px)
            translateX(10px)
            rotate(42deg); 
        position: absolute;
        margin-top: 2rem;
        margin-left: .4rem;
      
    }
    .line3 {
        width: 110px;
        height: 40px;
        border-bottom: 1px solid rgb(173, 154, 154);
        -webkit-transform:
            translateY(-20px)
            translateX(-5px)
            rotate(-42deg); 
        position: absolute;
        margin-top: 2rem;
        margin-left: .4rem;
       
    }
}