.mainconnected{
    color: antiquewhite;
    display: flex;
    flex-direction: column;
    margin-top: -2rem;
}
.connectedtext{
    font-style: italic;
    font-size: 1.5rem;
    text-align: center;
   
}
.connectedtext22{
    font-style: italic;
    font-size: 4rem;
    text-align: center;
    margin-top: 2rem; 
}
.connectedtext44{
    font-style: italic;
    font-size: 1.2rem;
    text-align: center;
}
.circcle22{
    display: flex  ;
    justify-content: center  ;
    align-items: center ;
    margin-top: 2rem;
    margin-bottom: 3rem;
 
}
.circle{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    display: inline-block;
    border: solid 5px wheat;
    
  }
  .minttext{
    text-align: center;
    font-size: 1.5rem;
    color: bisque;

  }
  .connectedtext77{
    font-style: italic;
    font-size: 1.2rem;
    text-align: center;
     
  }
  .nine22{
    color: antiquewhite;
    position: absolute;
    font-size: 2rem;
    left: 0;
    top: 0;
    margin-left: 7rem;
    margin-top: 2rem;
  }

  .loadingtext{
    font-style: italic;
    font-size: 1.8rem;
    text-align: center;
  }
  @media(max-width: 510px){
    .mainconnected{
      color: antiquewhite;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
  }
    .connectedtext{
      font-style: italic;
      font-size: .8rem;
      text-align: center;
      
     
  }
  .connectedtext22{
      font-style: italic;
      font-size: 1.8rem;
      text-align: center;
      margin-top: 1rem;
  }
  .connectedtext44{
    font-style: italic;
    font-size: .8rem;
    text-align: center;
}
.circle{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height:3rem;
  width: 3rem;
  border-radius: 50%;
  display: inline-block;
  border: solid 5px wheat;
  
}
.minttext{
  text-align: center;
  font-size: 1rem;
  color: bisque;

}
.connectedtext77{
  font-style: italic;
  font-size: .8rem;
  text-align: center;
  margin-top: 2rem;
   
}
.nine22{
  color: antiquewhite;
  position: absolute;
  font-size: 1.8rem;
  left: 0;
  top: 0;
  margin-left: 7rem;
  margin-top: 2rem;
}
  }