.mainlogo{
    background-image: url('../assets//Images/dxd_BG_04_B.jpg');
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


.imageoflogo{
    height: 6rem;
    width: 6rem;
    position: absolute;
    left: 0;
    top:0;
    transform: rotate(45deg);

}
@media(max-width: 510px){
    .mainlogo{
        background-image: url('../assets/Images/dxd_BG_04_B.jpg');
        height: 100vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
