@font-face {
  font-family: "Normal";   /*Can be any text*/
  src: local("Cochin"),
    url("./assets/Fonts/Cochin.ttf") format("truetype");
}

*{
  font-family: "Cochin";   /*Can be any text*/
}
*{
  margin: 0;
  padding: 0;
}